/// <reference path="../../../typings/libraries.d.ts"/>

interface ILocale {
	Language: string;
	IsCurrent: boolean;
	Id: number;
	CultureCode: string;
}

var Alkami = Alkami || {};

(function () {
	class LanguageToggle {

		private _locales: ILocale[];
		private _localeStorageKey = "alkami.locales";
		private _chosenLocaleIndex = -1;
		private _languageItemTemplateElement;

		public changeLocale(locale: ILocale): Promise<ILocale[]> {
			return new Promise<ILocale[]>((resolve) => {
				if (locale.IsCurrent) {
					return resolve(this._locales);
				}

				$.post('/API/Locales/SetLocale', { localeId: locale.Id }, () => {
					this._locales.forEach(loc => loc.IsCurrent = loc.Id === locale.Id);

					this.saveLocales(this._locales);

					if (Alkami.WidgetNav) {
						Alkami.WidgetNav.ResetWidgetNav();
						Alkami.WidgetNav.ResetWidgetTitleBars();
					}
					return resolve(this._locales);
				});
			});

		}

		public saveLocales(locales: ILocale[]) {
			if (!(Alkami.Utils.StorageHelper.hasLocalStorage() && locales && locales.length)) {
				return;
			}

			localStorage[this._localeStorageKey] = JSON.stringify(locales);
		}

		public getLocales(cb?: (locales: ILocale[]) => void): Promise<ILocale[]> {
			return new Promise<ILocale[]>((resolve) => {
				if (Alkami.Utils.StorageHelper.hasLocalStorage() && localStorage[this._localeStorageKey]) {
					const locales: ILocale[] = JSON.parse(localStorage[this._localeStorageKey]);
					return resolve(locales);
				}

				$.get('/API/Locales', (data) => {
					const locales: ILocale[] = data;
					Alkami.LanguageToggle.SaveLocales(locales);
					resolve(locales);
				}, 'json');
			}).then((locales) => {
				this._locales = locales;
				if (cb) {
					cb(locales);
				}

				Alkami.Dom.dispatchEvent(document.documentElement, 'localesLoaded');
				return locales;
			});
		}

		public resetLocales() {
			if (!(Alkami.Utils.StorageHelper.hasLocalStorage() && localStorage[this._localeStorageKey])) {
				return;
			}

			localStorage.removeItem(this._localeStorageKey);
		}

		public async refreshUI() {
			const languageToggleElement = document.getElementById('language_toggle');
			if (!languageToggleElement) {
				return;
			}

			if (!this._languageItemTemplateElement) {
				this._languageItemTemplateElement = document.getElementById('language_item_template');
			}

			Alkami.Dom.removeChildNodes(languageToggleElement);
			languageToggleElement.innerHTML = '';

			const locales = await this.getLocales();
			languageToggleElement.setAttribute('data-locale-count', locales.length.toString(10));

			if (locales.length > 1) {
				Alkami.Dom.showElement(languageToggleElement);
			} else {
				Alkami.Dom.hideElement(languageToggleElement);
			}

			locales.forEach((locale, index) => {
				const linkElement = this._languageItemTemplateElement.cloneNode(true);
				linkElement.id = 'language_toggle_link' + index;
				linkElement.href = '#';
				linkElement.classList.add(locale.IsCurrent ? 'current' : 'not-active');
				linkElement.innerHTML = linkElement.innerHTML.replace('$language_item_name$', locale.Language);

				linkElement.addEventListener('click', (event) => {
					event.preventDefault();
					this._openDialog(locale, index);
				});
				linkElement.addEventListener('keydown', (event) => {
					if (event.keyCode === 13 || event.keyCode === 32) {
						event.preventDefault();
						this._openDialog(locale, index);
					}
				});

				languageToggleElement.appendChild(linkElement);
			});
		}

		public get locales() {
			return this._locales;
		}

		public get currentLocale() {
			return this._locales.find((locale) => locale.IsCurrent);
		}

		public get chosenLocale() {
			return this._locales[this._chosenLocaleIndex] || null;
		}

		private __dialog;
		private get _dialog() {
			if (this.__dialog) {
				return this.__dialog;
			}

			this.__dialog = Alkami.Helpers.createDialog({
				buttons: [{
					text: Alkami.Localization.SiteText['Desktop.Menu.LocaleConfirmation.CancelButton'],
					type: 'secondary',
					handlerName: 'cancel',
					closePrompt: true
				}, {
					text: Alkami.Localization.SiteText['Desktop.Menu.LocaleConfirmation.ConfirmButton'],
					handlerName: 'confirm',
					closePrompt: false
				}],
				cls: 'language-toggle-modal',
				content: `<div id="language_toggle_modal_description">
						<p>${Alkami.Localization.SiteText['Desktop.Menu.LocaleConfirmation.Description']}</p>
					</div>
					<div class="loader-container" id="language_toggle_modal_changing_description">
						<div class="loader"></div>
						<p>${Alkami.Localization.SiteText['Desktop.Menu.LocaleConfirmation.LoaderDescription']}</p>
					</div>`,
				handlers: {
					confirm: () => {
						const descriptionElement = document.getElementById('language_toggle_modal_description');
						const descriptionChangingElement = document.getElementById('language_toggle_modal_changing_description');

						Alkami.Dom.hideElement(descriptionElement);
						Alkami.Dom.showElement(descriptionChangingElement);

						Array.from<HTMLButtonElement>(this.__dialog.element.querySelectorAll('button')).forEach((button: HTMLButtonElement) => {
							button.disabled = true;
						});

						this.changeLocale(this.chosenLocale).then(() => {
							location.reload();
							//this.__dialog.open = false;
						})
					}
				},
				id: 'language_toggle_modal',
				title: Alkami.Localization.SiteText['Desktop.Menu.LocaleConfirmation.Title']
			});

			this.__dialog.element.addEventListener('iris.prompt.opening', () => {
				const descriptionElement = document.getElementById('language_toggle_modal_description');
				const descriptionChangingElement = document.getElementById('language_toggle_modal_changing_description');


				Alkami.Dom.hideElement(descriptionChangingElement);
				Alkami.Dom.showElement(descriptionElement);

				Array.from<HTMLButtonElement>(this.__dialog.element.querySelectorAll('button')).forEach((button: HTMLButtonElement) => {
					button.disabled = false;
				});

			});

			return this.__dialog;
		};

		private _openDialog = (locale: object, index: number) => {
			if (locale === this.currentLocale) {
				return;
			}

			this._chosenLocaleIndex = index;
			this._dialog.open = true;
		};

		public GetLocales = this.getLocales;
		public ResetLocales = this.resetLocales;
		public SaveLocales = this.saveLocales;
	}

	const lt = (Alkami.LanguageToggle || new LanguageToggle()) as LanguageToggle;
	Alkami.LanguageToggle = lt;

	Alkami.Dom.onDocumentReady(() => {
		const languageToggleElement = document.getElementById('language_toggle');

		if (!languageToggleElement) {
			return;
		}

		languageToggleElement.setAttribute('data-locale-count', '0');
		Alkami.Dom.hideElement(languageToggleElement);
		
		lt.refreshUI();
	});
})();
